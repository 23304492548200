import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

gsap.registerPlugin(
  MotionPathPlugin,
  ScrollTrigger,
  ScrollToPlugin,
  CustomEase,
  DrawSVGPlugin,
  ScrambleTextPlugin
);

// Create matchMediaList
let smVar = 640,
    mdVar = 768,
    lgVar = 1024,
    xlVar = 1280,
    xxlVar = 1536,
    maxVar = 1980;


// matchQueries
let mdOnlyQuery = window.matchMedia('screen and (max-width: 768px)'),
    smQuery = window.matchMedia('screen and (min-width: 640px)'),
    mdQuery = window.matchMedia('screen and (min-width: 768px)'),
    lgQuery = window.matchMedia('screen and (min-width: 1024px)'),
    xlQuery = window.matchMedia('screen and (min-width: 1280px)'),
    xxlQuery = window.matchMedia('screen and (min-width: 1536px)'),
    maxQuery = window.matchMedia('screen and (min-width: 2560px)');

// Force page scroll position to top at page refresh in HTML
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
} else {
  window.onbeforeunload = function () {
      window.scrollTo(0, 0);
  }
}

let bgTrigger = document.querySelector(".bg-wrapper");
let bgImgTrigger = document.querySelector(".image-bg");
let logoTrigger = document.querySelector(".image-logo");

// SECTION PARALLAX BG
gsap
  .fromTo(bgTrigger, {
  css: {
      y: 0,
      position: "absolute",
      autoAlpha: 1
    },
  }, {
  y: "-20%",
  autoAlpha: 0,
  ease: "none",
  scrollTrigger: {
    trigger: ".tagline",
    scrub: 0.3,
  },
}), "bgHead";

gsap.fromTo(logoTrigger, {
  y: "0%",
  autoAlpha: 1,
  }, {
  y: "-20%",
  autoAlpha: 0,
  ease: "none",
  scrollTrigger: {
    trigger: ".tagline",
    scrub: 0.3,
  },
});

// NAVBAR MOBILE
// const btnMobileMenu = document.querySelector('.btn .mobile-menu');
// const closeMobileMenu = document.querySelector('.close .mobile-menu');
// btnMobileMenu.addEventListener('click', e => {
//   document.querySelector('.mobile-menu').classList.remove('hidden', 'duration-100', 'ease-in', 'opacity-0', 'scale-95')
//   document.querySelector('.mobile-menu').classList.add('duration-200', 'ease-out', 'opacity-100', 'scale-100')
// })
// closeMobileMenu.addEventListener('click', e => {
//   document.querySelector('.mobile-menu').classList.remove('duration-200', 'ease-out', 'opacity-100', 'scale-100')
//   document.querySelector('.mobile-menu').classList.add('hidden', 'duration-100', 'ease-in', 'opacity-0', 'scale-95')
// })


// SECTION LOAD / HERO
let loadTl = gsap.timeline({});
  loadTl
    .set(".splash", {autoAlpha: 1})

    //set paramethers logo
    .set(".cta .link", {autoAlpha:0})
    .set([".logo .focus-left", ".logo .focus-right"], {autoAlpha: 0})
    .set([".logo .focus-left"], {x: 40})
    .set([".logo .focus-right"], {x: -40})
    .set(".logo .trin", {x: 20})
    .set(".logo .y", {x: -20})

    .to(".loader", {duration:1, autoAlpha: 0})
    .add(animLoad())
    .to(".splash", {display: "none"})
    .to(".image-bg", {scale: 1.5, autoAlpha:1, duration: 1}, "splash-=4.5")
    .fromTo(".image-logo", {scale: 0}, {scale: 1, autoAlpha: 1}, "splash-=4.5")
    .add(animTitle(), "-=2.5")
    .fromTo(".scroll-down", {autoAlpha:0}, {autoAlpha:1, ease:"expo.out",
      scrollTrigger: {
        trigger: "#hero",
      }}, "-=2.5")

function animLoad() {
  let bracketLeftMove = window.innerWidth < mdVar ? "-=50" : "-=125",
      bracketRightMove = window.innerWidth < mdVar ? "+=50" : "+=125";
  const tl = gsap
    .timeline()
    .to([], {duration: 1})
    .to(".image-bracket-left", {x:bracketLeftMove, autoAlpha:1, ease:"expo.out"}, "focus")
    .to(".image-bracket-right", {x:bracketRightMove, autoAlpha:1, ease:"expo.out"}, "focus")
    .to(".image-eagle", {scale: 6, autoAlpha: 0,duration: 3, ease:"expo.out", id: "test"}, "fade")
    .to(".image-bracket-left", {duration:2, autoAlpha:0, ease:"expo.out"}, "fade")
    .to(".image-bracket-right", {duration:2, autoAlpha:0, ease:"expo.out"}, "fade")
    .to('.splash h1', {scale: 1, opacity: 0}, "fade")
  return tl;
}

function animTitle() {
  let imgLogoMove = window.innerWidth < lgVar ? "0" : "-=6.5%";
  const tl = gsap
    .timeline()
    .to(".image-logo", {x: imgLogoMove}, "logoFocus")
    .to(".logo .focus-left", {autoAlpha: 1, x: 0}, "logoFocus")
    .to(".logo .focus-right", {autoAlpha: 1, x: 0}, "logoFocus")
    .to(".logo .trin", {x: 0}, "logoFocus")
    .to(".logo .y", {x: 0}, "logoFocus")
  return tl;
}


// SECTION TAGLINE
if (mdOnlyQuery.matches) {
  let taglineMobileTrigger = document.querySelector(".tagline .mobile");
  const taglineMobileTl = gsap
  .timeline({
    scrollTrigger: {
        trigger: ".tagline .mobile",
        pin: true,
        scrub: 1,
        start: "center center",
        triggerHook: 0,
        end: "+=" + (window.innerHeight * 3),
        onEnter: () => gsap.to(taglineMobileTrigger, {
          duration: 2,
          opacity: 1,
        }),
        onEnterBack: () => gsap.to(taglineMobileTrigger, {
          duration: 2,
          opacity: 1,
        }),
        onLeaveBack: () => gsap.to(taglineMobileTrigger, {
          duration: 2,
          opacity: 0,
        }),
  },})


taglineMobileTl
  .to(".logo", {autoAlpha: 0})
  .fromTo(
    ".tagline .mobile .text-secondary", {
      autoAlpha: 0
    }, {
    duration: 2,
    ease: "none",
    css:{
      color:"rgb(255, 255, 255)",
      autoAlpha: 1
    },
    stagger: 2,
    }
  )
  .to(
    ".tagline .mobile .text-secondary", {
      css:{
        color:"rgb(30, 74, 74)",
        autoAlpha: 1
      },
      ease:"power1.out",
      duration: 3,
      stagger: 2,
    }, "taglinetext"
  )
  .fromTo( ".tagline .mobile span", {
      y: "100%",
      opacity: 0,
    }, {
      opacity: 1,
      delay: .1 * 2,
      duration: 1.5,
      stagger: 1,
      y: "0%"
  }, "taglinetext+=1" )
  .to([], {duration: 5})


} else if (lgQuery.matches) {
  let h2Move = window.innerWidth < mdVar ? "+=35" : "0";
  let taglineTrigger = document.querySelector(".tagline-wrapper");

  ScrollTrigger.create({
    trigger: ".tagline",
    start: "top top",
    end: "+=" + (window.innerHeight * 1.5),
    pin: true,
    onEnter: () => linkCta("tagline"),
    onEnterBack: () => linkCta("tagline"),
  })

  const taglineTl = gsap
    .timeline({
      scrollTrigger: {
          y: "20%",
          trigger: ".tagline",
          scrub: 5,
          start: "center center",
          end: "+=" + (window.innerHeight * 1),
    },})

  taglineTl
    .set(".scroll-down", {autoAlpha:0, ease:"expo.out"})
    .fromTo(
      ".tagline-wrapper span", {
        autoAlpha: 0
      }, {
      duration: 2,
      ease: "none",
      css:{
        color:"rgb(255, 255, 255)",
        autoAlpha: 1
      },
      scrambleText:{
        text:"{original}",
        delay:1,
        revealDelay:0.5,
        tweenLength:true},
      stagger: 1,
      }
    )
    .fromTo(".cta .link", {autoAlpha: 0}, {autoAlpha: 1, ease:"expo.out"})
    .to(
      ".tagline-wrapper span", {
        css:{
          color:"rgb(30, 74, 74)",
          autoAlpha: 1
        },
        ease:"power1.out",
        duration: 2,
        stagger: 1,
      }, "taglinetext"
    )
    .fromTo(
      ".tagline-wrapper h2",{
      autoAlpha: 0,
      scale: 3,
      y: h2Move
      }, {
      scale: 1,
      autoAlpha: 1,
      duration: 2,
      stagger: 1,
      scrambleText:{
        text:"{original}",
        delay:1,
        revealDelay:0.5,
        tweenLength:true},
      }, "taglinetext+=1"
    )
    .to([], {duration: 5})

  function taglineAnimation() {
    const tl = gsap
    .timeline()
    return tl;
  }
}

// SECTION GRAPHS
if (mdOnlyQuery.matches) {
  const graphsTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graphs .mobile",
      scrub: 1,
      pin: true,
      start: "center center",
      onEnter: () => linkCta("graphs"),
      onEnterBack: () => linkCta("graphs"),
    }
  });
  graphsTl
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    .set(".graphs .mobile .wrapper", {
      top:"50%",
      height: "auto"
    })
    .set(
      ".graphs .mobile .graph-img", {
        width: "100%"
      }
    )
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    .fromTo([
      ".graphs .mobile .graph-img .graph5",
      ".graphs .mobile .graph-img .graph1",
      ".graphs .mobile .graph-img .graph2",
      ".graphs .mobile .graph-img .graph3",
      ".graphs .mobile .graph-img .graph4"
    ], {
      y: "100%",
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
        delay: .1 * 2,
        duration: 1.5,
        stagger: 1,
        y: "0%"
    }, "graphs")
    .fromTo([
      ".graphs .mobile .graph-img .focus",
    ], {
      opacity:0, scale: 2
    }, {
      autoAlpha: 1, scale: 1, duration: 1
    }, "focus")
    .fromTo( ".graphs .mobile .graph-img .notification", {
      autoAlpha: 0
    } , {
      autoAlpha: 1
    }, "notfication")

    .fromTo([
      ".graphs .mobile .text1 span",
      ".graphs .mobile .text2 span",
      ".graphs .mobile .text3 span",
      ".graphs .mobile .text4 span" ], {
        y: "100%",
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        delay: .1 * 2,
        duration: 1.5,
        stagger: 1,
        y: "0%"
      }, "graphs")
    .to(
      ".graphs .mobile .graph-img", {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          ease: "none",
          // start: "center center",
          stagger: 1,
          delay: 1,
      }, "graphs")
    .to(".graphs .mobile .graph-img", {
          right:"30%",
          scale: 1,
          duration: 7.5,
          stagger: 1,
          delay: 1,
      }, "graphs+=2")
} else if (lgQuery.matches) {
  const triggerGraphs = document.querySelector(".graphs")

  ScrollTrigger.create({
    trigger: ".graphs .desktop",
    start: "top top",
    end: "+=" + (window.innerHeight * 6),
    pin: true,
  })

  const graphsTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graphs .desktop",
      scrub: 1,
      end: "+=" + (window.innerHeight * 6),
      onEnter: () => linkCta("graphs"),
      onEnterBack: () => linkCta("graphs"),
    }
  });


  graphsTl
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    .to(".image-bg", {scale: 1})
    .set(".graph1", {skewX:0, skewY:352, top:"auto", left:"30%", backgroundColor:"rgba(60, 60, 60, .75)", opacity:0, scale: 0.5})
    .set(".graph2", {skewX:0, skewY:352, top:"50%", left:"-=100", backgroundColor:"rgba(60, 60, 60, .75)", opacity:0, scale: 0.5})
    .set(".graph3", {skewX:0, skewY:352, top:"60%", left:"50%", backgroundColor:"rgba(60, 60, 60, .75)", opacity:0, scale: 0.5})
    .set(".graph4", {skewX:0, skewY:352, top:"80%", left:"70%", backgroundColor:"rgba(60, 60, 60, .75)", opacity:0, scale: 0.5})
    .set(".graph-full", {skewX:0, skewY:352, top:"20%", left:"-=50", backgroundColor:"rgba(60, 60, 60, .75)", opacity:0, scale: 0.5})
    .set(".graph3 .graph-focus", {opacity:0, scale: 2})
    .set([".graph3 .graph-box", ".graphs .text5"], {autoAlpha:0})
    .set([
      ".graphs .text1",
      ".graphs .text2",
      ".graphs .text3",
      ".graphs .text4",
    ], {y: 100})

    .to(".graphs .text1", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"}, "graphs")
    .to(".graphs .text1", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"}, "graphs+=7")
    .to(
      ".graph-img", {
          autoAlpha: 1,
          yPercent: 0,
          duration: 1,
          ease: "none",
          start: "center center",
          stagger: 1,
          delay: 1,
      }, "graphs")
    .to(
      ".graph-img", {
          left:"50%",
          top:"50%",
          scale: 1.25,
          duration: 7.5,
          stagger: 1,
          delay: 1,
          skewX: 0,
          skewY: 360
      }, "graphs+=2")
    .to([".graph1", ".graph2", ".graph3", ".graph4"], { backgroundColor: "rgba(60, 60, 60, 0)", duration: 4}, "graphs+=7.5")

    .to(".graphs .text2", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"}, "graphs+=9.5")
    .to(".cta .link", {autoAlpha: 1, ease:"expo.out"}, "graphs+=9.5")
    .to(".graph-full", { backgroundColor: "rgba(60, 60, 60, .75)", duration: 1, }, "graphs+=9.5")
    .to(".graphs .text2", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"}, "graphs+=13.5")
    .to(".graphs .text3", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"}, "graphs+=14.5")
    .to(".graphs .text3", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"}, "graphs+=18.5")
    .to(".graph3 .graph-focus", { autoAlpha: 1, scale: 1, duration: 1, }, "graphFocus")
    .to(".graph3 .graph-box", { autoAlpha: 1, duration: 1}, "+=4")
    .to(".graphs .text4", {y: 0, duration: 1,autoAlpha:1, ease:"expo.out"}, "+=4")
    .call(linkCta, ["graphs"])
}


// SECTION SERVER
if (mdOnlyQuery.matches) {
  const serverTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graph-server.mobile",
      scrub: 1,
      pin: true,
      start: "center center",
      onEnter: () => linkCta("graph-server"),
      onEnterBack: () => linkCta("graph-server")
    },
  })

  serverTl
    .set([
      ".doc",
      ".folder",
      ".flash",
  ], {
      autoAlpha: 0
  })
    .to([
      ".doc",
      ".folder",
      ".flash",
  ], {
      autoAlpha: 0
  })
  .to([
    ".server",
    ".switch",
    ".virt",
    ".cloud",
    ".obj-comp1",
    ".obj-comp2",
    ".obj-comp3",
    ".connect2",
    ".focus-comp1",
    ".focus-comp3",
    ".focus-server",
    ".focus-switch",
    ".focus"
  ], {duration:3, autoAlpha: 0}, "graphs+=4")
  .to([
    ".doc",
    ".folder",
  ], {duration:3, autoAlpha: 1}, "graphs+=4")
    .fromTo([
      ".graph-server.mobile .text1 span",
      ".graph-server.mobile .text2 span",
      ".graph-server.mobile .text3 span" ], {
        y: "100%",
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        delay: .1 * 2,
        duration: 1.5,
        stagger: 1,
        y: "0%"
      }, "graphs")
} else if (lgQuery.matches) {
  // bg parallax appear
  gsap.set(".img-server", {autoAlpha: 0})

  ScrollTrigger.create({
    trigger: ".graph-server.desktop",
    start: "top top",
    end: "+=" + (window.innerHeight * 7),
    pin: true,
    onEnter: () => linkCta("graph-server"),
    onEnterBack: () => linkCta("graph-server")
  })

  gsap.fromTo(bgTrigger, {
    css: {
      y: "10%",
      autoAlpha: 0,
      position: "fixed",
    },
    }, {
    y: "0%",
    autoAlpha: 0.2,
    ease: "none",
    force3D:true,
    scrollTrigger: {
      trigger: ".graph-server",
      scrub: 0.3,
    },
  });

  const serverTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graph-server.desktop",
      scrub: 1,
      start: "top center",
      end: "+=" + (window.innerHeight * 7),
    },
  })


  serverTl
    .set(".img-server", {
      left:"50%",
      top:"50%",
    })
    .set([
      ".graph-server .text1",
      ".graph-server .text2",
    ], {y: 100})
    .set([
        ".graph-server .text1",
        ".graph-server .text2",
        ".focus-comp1",
        ".focus-comp3",
        ".focus-server",
        ".focus-switch",
        ".focus",
        ".doc",
        ".folder",
        ".flash",
        ".connect1 path",
        ".connect2 path",
    ], {
        autoAlpha: 0
    })
    .set([
      ".server",
      ".switch",
      ".virt",
      ".cloud",
      ".obj-comp1",
      ".obj-comp2",
      ".obj-comp3",
    ] , {
        autoAlpha: 0, yPercent: -10,
    })
    .add(animServer())

  function animServer() {
    const tl = gsap.timeline({})

    tl
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    .to(".img-server", {autoAlpha: 1})
    .to([
        ".server",
        ".switch",
        ".virt",
        ".cloud",
        ".obj-comp1",
        ".obj-comp2",
        ".obj-comp3",
    ] , {
        stagger: .5, duration: 1, yPercent: 0, autoAlpha: 1
    })

    .fromTo([".connect1 path", ".connect2 path"], {drawSVG:"0%"}, {yPercent: 0, duration: 5, drawSVG:"100%", autoAlpha: 1, stagger: .5 }, "-=1")

    .to([
        ".focus-comp1",
        ".focus-comp3",
        ".focus-server",
        ".focus-switch",
    ], {
        transformOrigin:"center center", autoAlpha: 1, stagger: 2, delay: 1, ease: "Bounce.easeOut"
    }, "red+=9")
    .to(".graph-server .text1", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"},"+=4")
    .to(".graph-server .text1", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"},"+=4")
    .to(".focus", {duration: 4, autoAlpha: 1, ease: "power4.out"})
    .to(".graph-server .text2", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"}, "+=4")

    .to([
      ".cloud",
      ".obj-comp1",
      ".obj-comp2",
      ".obj-comp3",
      ".connect2",
      ".focus-comp1",
      ".focus-comp3",
      ".focus-server",
      ".focus-switch",
      ".focus"
    ], {duration:3, autoAlpha: 0}, "minuten+=6")
    .to(".graph-server .text2", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"}, "minuten+=6")
    .to(".folder", {duration: 2, autoAlpha: 1}, "folderLink+=3")
    .to(".cta .link", {autoAlpha: 1, ease:"expo.out"}, "folderLink")
    .to(".flash", {autoAlpha: 1, backgroundColor:"#FFF", duration: 0.05}, "+=6")
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    .to(".flash", {duration: 5, ease: "power4.out", autoAlpha: 0})

    .set([
        ".server",
        ".switch",
        ".virt",
        ".obj-comp1",
        ".obj-comp2",
        ".obj-comp3",
        ".cloud",
        ".focus",
        ".connect1",
        ".connect2",
        ".focus-server",
        ".focus-switch",
    ], {autoAlpha: 0}, "-=5")
    .to(".doc", {autoAlpha: 1, duration: 1}, "-=4")
    .to(".doc", {xPercent: 125,yPercent: 50,scale: 0,duration: 2}, "moveCenter")
    .to(".folder", {xPercent:-150, duration: 2}, "moveCenter")
    .to(".graph-server .text3", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"}, "moveCenter")
    .to([], {duration: 5})
    .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})

    return tl;
  }
}

// SECTION GRAPH LINES
if (mdOnlyQuery.matches) {
  const linesTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graph-lines .mobile",
      scrub: 1,
      pin: true,
      start: "center center",
      onEnter: () => linkCta("graph-lines"),
      onEnterBack: () => linkCta("graph-lines")
    },
  })
  linesTl
    .fromTo(".line-before", {autoAlpha: 0}, {autoAlpha: 1, ease: "none", duration: 2}, "lineBefore")
    .fromTo(".line-after", {autoAlpha: 0}, {autoAlpha: 1, ease: "none", duration: 2}, "lineAfter")

    .fromTo(".graph-prediction",{autoAlpha:0}, {autoAlpha:1 , ease: "none", duration: 2}, "lineBefore+=2")
    .fromTo(".line-predict", {autoAlpha: 0}, {autoAlpha: 1, ease: "none", duration: 1}, "<lineAfter")
    .fromTo([".graph-alert1", ".graph-alert2"], {autoAlpha:0}, {autoAlpha:1 ,scale: 1, ease: "Expo.easeInOut"}, "alert")
    .fromTo([
      ".graph-lines .mobile .text1 span",
      ".graph-lines .mobile .text2 span" ], {
        y: "100%",
        autoAlpha: 0,
      }, {
        autoAlpha: 1,
        delay: .1 * 2,
        duration: 1.5,
        stagger: 1,
        y: "0%"
      }, "lineBefore")
} else if (lgQuery.matches) {
  // bg parallax fade
  gsap.fromTo(bgTrigger, {
    css: {
      position: "fixed",
      y: "0%",
      autoAlpha: 0.5,},
    }, {
    y: "-10%",
    autoAlpha: 0,
    ease: "none",
    scrollTrigger: {
      trigger: ".graph-lines .desktop",
      scrub: 1,
    },
  }), "bgFade";

  const triggerLines = document.querySelector(".graph-lines")
  const linesTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".graph-lines .desktop",
      scrub: 1,
      pin: true,
      end: "bottom -300%",
      onEnter: () => linkCta("graph-lines"),
      onEnterBack: () => linkCta("graph-lines")
    },
  })



  linesTl
    .set(".graph-lines .graph", {
      left:"50%",
      top:"50%",
      scale: 1
    })
    .set([".graph-prediction", ".graph-lines .text3"], {autoAlpha: 0})
    .set(".line-predict", {autoAlpha: 1})
    .set(".line-before", {autoAlpha: 1})
    .set(".line-after", {autoAlpha: 1})
    .set([".graph-alert1-focus", ".graph-alert2-focus"], {autoAlpha: 0, scale: 2})
    .set([".graph-alert1 .square", ".graph-alert2 .square"], {autoAlpha: 0})
    .set([".graph-alert1-bg", ".graph-alert2-bg"], {autoAlpha: 0})
    .set([".graph-alert1 text", ".graph-alert2 text"], {autoAlpha: 0})
    // .set([".graph-alert1 path:last-child", ".graph-alert2 path:last-child"], {autoAlpha: 0})
    .set([
      ".graph-lines .text1",
      ".graph-lines .text2",
    ], {y: 100})
    .add(animLines())

  function animLines() {
    let textMove = window.innerWidth < maxVar ? "200" : "400";
    const tl = gsap.timeline({})
    tl
      .fromTo(".line-before", {drawSVG: "0%"}, {drawSVG: "100%", ease: "none", duration: 4}, "lineBefore")
      .fromTo(".line-after", {drawSVG: "0%"}, {drawSVG: "100%", ease: "none", duration: 2}, "lineAfter")

      .to(".graph-prediction", {autoAlpha:1 , ease: "none", duration: 5}, "lineBefore+=2")
      .fromTo(".line-predict", {drawSVG: "0%"}, {drawSVG: "100%", ease: "none", duration: 1}, "<lineAfter")
      .to(".cta .link", {autoAlpha: 1, ease:"expo.out"})
      .to(".graph-lines .text1", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"})
      .to(".graph-lines .text1", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"}, "alert")
      .to([".graph-alert1-focus", ".graph-alert2-focus"], {autoAlpha:1, duration: 1,scale: 1, ease: "Expo.easeInOut"}, "alert")
      .to([".graph-alert1 .square", ".graph-alert2 .square"], {autoAlpha:1, duration: 1, ease: "Expo.easeInOut"}, "alert+=1")
      .to([".graph-alert1-bg", ".graph-alert2-bg"], {autoAlpha:1, duration: 1, ease: "Expo.easeInOut"}, "alert+=1")
      .to([".graph-alert1 text", ".graph-alert2 text"], {autoAlpha:1, duration: 1, ease: "Expo.easeInOut"}, "alert+=1")
      // .to([".graph-alert1 path:last-child", ".graph-alert2 path:last-child"], {autoAlpha:1, duration: 2, ease: "Expo.easeInOut"}, "alert+=1")
      .to(".graph-lines .text2", {y: 0, duration: 3,autoAlpha:1, ease:"expo.out"})
      // .to(".graph-lines .text2", {y: -100, duration: 3,autoAlpha:0, ease:"expo.out"})
      .to(".cta .link", {autoAlpha: 0, ease:"expo.out"})
    return tl;
  }
}

// LOADING SECTION
const loadingTl = gsap.timeline({
  scrollTrigger: {
    trigger: ".graph-loading",
    scrub: 1,
    pin: true,
    end: "bottom -100%",
    onEnter: () => linkCta("graph-loading"),
    onEnterBack: () => linkCta("graph-loading")
  },
})

loadingTl
  .set(".graph-loading .loading", {
    left:"50%",
    top:"50%",
  })
  .add(animLoading())


function animLoading() {
  const
    loadingImg = document.querySelector(".loading"),
  ;
  const textMove = window.innerWidth < maxVar ? "250" : "350";
  const tl = gsap.timeline({})
  tl
    .fromTo( ".graph-loading .text3 span", {
      y: "100%",
      opacity: 0,
    }, {
      opacity: 1,
      delay: .1 * 2,
      duration: 1.5,
      stagger: 1,
      y: "0%"
    },"loading" )
    .to(".cta .link", {autoAlpha: 1, ease:"expo.out"})

    // loading charge / check ok
    .from(".load-bar", {duration: 1.5, scaleX:0, transformOrigin:"left center"})
    .to(".logo-center", {duration: 1, autoAlpha:0})
    .from(".check", {duration: 1, drawSVG:"0%"})

    .to(".cta .link", {duration: 3, autoAlpha:0, ease:"expo.out"})
  return tl;
}

function linkCta(section) {
  const link = document.querySelector(".cta .link");

  if(section == "graphs") {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits#5-metrics');
    } else {
        link.setAttribute('href', '/benefits#5-metrics');
    }
  } else if (section == "graph-server") {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits#server-section');
    } else {
        link.setAttribute('href', '/benefits#server-section');
    }
  } else if (section == "tagline") {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits#protect-predict-proceed');
    } else {
        link.setAttribute('href', '/benefits#protect-predict-proceed');
    }
  } else if (section == "graph-lines") {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits#kuenstliche-intelligenz-statt-hardware');
    } else {
        link.setAttribute('href', '/benefits#kuenstliche-intelligenz-statt-hardware');
    }
  } else if (section == "graph-loading") {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits#alarm-durch-kuenstliche-intelligenz');
    } else {
        link.setAttribute('href', '/benefits#alarm-durch-kuenstliche-intelligenz');
    }
  } else {
    if(window.location.href.indexOf("/en/") > -1) {
        link.setAttribute('href', '/en/benefits');
    } else {
        link.setAttribute('href', '/benefits');
    }
  }
}
